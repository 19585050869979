<script>
	import { page } from "$app/stores";
</script>

<nav
	class="invisible mb-4 h-5 w-full items-center justify-around md:visible md:flex md:h-20"
>
	<div class="">
		<a href="/">
			<button
				class="mx-4 text-lg text-neutral-300 duration-200 hover:font-bold {$page
					.route.id === '/' &&
					'border-b-2 border-solid border-b-think-500 font-bold text-think-500'} "
				>Perfil</button
			>
		</a>

		<a href="/project">
			<button
				class="mx-4 text-lg text-neutral-300 duration-200 hover:font-bold {$page
					.route.id === '/project' &&
					'border-b-2 border-solid border-b-think-500 font-bold text-think-500'} "
				>Projeto</button
			>
		</a>

		<a href="/videos">
			<button
				class="mx-4 text-lg text-neutral-300 duration-200 hover:font-bold {$page
					.route.id === '/videos' &&
					'border-b-2 border-solid border-b-think-500 font-bold text-think-500'}"
				>Vídeos</button
			>
		</a>

		<a href="/methodology">
			<button
				class="mx-4 text-lg text-neutral-300 duration-200 hover:font-bold {$page
					.route.id === '/methodology' &&
					'border-b-2 border-solid border-b-think-500 font-bold text-think-500'}"
				>Metodologia</button
			>
		</a>
	</div>
</nav>

<nav
	class="fixed bottom-0 left-0 z-50 flex h-[72px] w-screen bg-[#E4E4E4] md:hidden"
>
	<a
		class="flex w-1/4 flex-col items-center justify-center {$page.route.id ===
		'/'
			? 'border-t-4 border-t-think-500'
			: ''}"
		href="/"
	>
		<img
			alt="Home"
			src="/img/icons/{$page.route.id === '/'
				? 'home-selected'
				: 'home'}-icon.svg"
		/>
		<button
			class="p-1 text-sm text-neutral-300 duration-200 hover:font-light {$page
				.route.id === '/'
				? 'font-bold text-think-500'
				: ''}">Perfil</button
		>
	</a>
	<a
		class="flex w-1/4 flex-col items-center justify-center {$page.route.id ===
		'/project'
			? 'border-t-4 border-t-think-500'
			: ''}"
		href="/project"
	>
		<img
			alt="Project"
			src="/img/icons/{$page.route.id === '/project'
				? 'project-selected'
				: 'project'}-icon.svg"
		/>
		<button
			class="p-1 text-sm text-neutral-300 duration-200 hover:font-light {$page
				.route.id === '/project'
				? 'font-bold text-think-500'
				: ''}">Projeto</button
		>
	</a>
	<a
		class="flex w-1/4 flex-col items-center justify-center {$page.route.id ===
		'/videos'
			? 'border-t-4 border-t-think-500'
			: ''}"
		href="/videos"
	>
		<img
			alt="Videos"
			src="/img/icons/{$page.route.id === '/videos'
				? 'videos-selected'
				: 'videos'}-icon.svg"
		/>
		<button
			class="p-1 text-sm text-neutral-300 duration-200 hover:font-light {$page
				.route.id === '/videos'
				? 'font-bold text-think-500'
				: ''}">Vídeos</button
		>
	</a>
	<a
		class="flex w-1/4 flex-col items-center justify-center {$page.route.id ===
		'/methodology'
			? 'border-t-4 border-t-think-500'
			: ''}"
		href="/methodology"
	>
		<img
			alt="Methodology"
			src="/img/icons/{$page.route.id === '/methodology'
				? 'methodology-selected'
				: 'methodology'}-icon.svg"
		/>
		<button
			class="p-1 text-sm text-neutral-300 duration-200 hover:font-light {$page
				.route.id === '/methodology'
				? 'font-bold text-think-500'
				: ''}">Metodologia</button
		>
	</a>
</nav>
